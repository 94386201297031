<template>
  <div>
    <b-card :title="$t('welcomeToWtwNetwork')">
      <b-card-text>{{ $t('tradeWithANetworkText') }}</b-card-text>
    </b-card>

    <b-card :title="$t('yourLeadingPlatform')">
      <b-card-text>{{ $t('specializedB2BPlatform') }}</b-card-text>
    </b-card>
  </div>
</template>

<script>
import { BCard, BCardText } from 'bootstrap-vue';

export default {
  components: {
    BCard,
    BCardText,
  },
};
</script>

<style>

</style>
